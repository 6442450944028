import { format } from 'date-fns'
import { capitalize, isNil } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionIcon, ActionType } from '@awell-health/design-system'
import { IconButton } from '../../components/Button'
import { Duplicate, Sendgrid } from '../../components/Icons'
import { LozengeColors } from '../../components/Lozenge'
import { Tooltip } from '../../components/Tooltip'
import { Colors } from '../../enums'
import {
  type Activity,
  ActivityAction,
  type ActivityContent,
  type ActivityEventType,
  ActivityObjectType,
  ActivityResolution,
  ActivityStatus,
  ActivitySubjectType,
  type SubActivity,
} from './types'

interface UseActivityContentHook {
  content: ActivityContent
  events: Array<ActivityEventType>
}

/*
 * This hook creates content for the activity card
 * i.e Activity Summary, Icon etc.
 * Why need a hook? To reuse this content in other parts of the app. Such as side panel.
 */
export const useActivityContent = (
  activity: Activity,
): UseActivityContentHook => {
  const { t } = useTranslation(['translation', 'activityfeed'])

  const createActivitySummary = (): string => {
    if (!isNil(activity) && activity.object.type === ActivityObjectType.Decision) {
      const outcomeSubactivity = activity.sub_activities.find(a => !isNil(a?.text?.en) && a.text.en.includes('Evaluated outcome'))
      const outcomeText = outcomeSubactivity?.text?.en ?? ''
      return `Decision - ${outcomeText}`
    }
    return activity?.object?.name || ''
  }

  const createStatusFromResolution = (): string => {
    if (activity.resolution === ActivityResolution.Failure) {
      return t('activityfeed:failed').toLocaleUpperCase()
    }
    return t('activityfeed:done').toLocaleUpperCase()
  }

  const createStatus = (): string => {
    switch (activity.status) {
      case ActivityStatus.Active: {
        const isM2M = [
          ActivityObjectType.EmrReport,
          ActivityObjectType.PluginAction,
          ActivityObjectType.ApiCall,
        ].includes(activity.object.type)
        if (isM2M) {
          return t('activityfeed:in_progress').toLocaleUpperCase()
        }
        return t('activityfeed:to_do').toLocaleUpperCase()
      }
      case ActivityStatus.Done:
        return createStatusFromResolution()
      case ActivityStatus.Canceled:
        return t('activityfeed:canceled').toLocaleUpperCase()
      case ActivityStatus.Failed:
        return t('activityfeed:failed').toLocaleUpperCase()
      case ActivityStatus.Expired:
        return t('activityfeed:expired').toLocaleUpperCase()
      default:
        return ''
    }
  }

  const getFormattedActivityCompletionDate = (): string => {
    const completionAction = [
      ActivityAction.Read,
      ActivityAction.Complete,
      ActivityAction.Submitted,
      ActivityAction.Computed,
      ActivityAction.Failed,
      ActivityAction.FailedToSend,
      ActivityAction.Processed,
      ActivityAction.Delegated,
    ]

    const { sub_activities } = activity
    const completionSubActivity = sub_activities.find(subActivity =>
      completionAction.includes(subActivity.action),
    )

    return !isNil(completionSubActivity) && !isNil(completionSubActivity?.date)
      ? format(new Date(completionSubActivity.date), 'LLLL do')
      : ''
  }

  const createActivityStatusSummary = (): string => {
    const stakeholder = activity.indirect_object?.name ?? ''

    const date =
      activity.status === ActivityStatus.Done
        ? getFormattedActivityCompletionDate()
        : ''

    switch (activity.object.type) {
      case ActivityObjectType.Message:
        if (activity.status === ActivityStatus.Active)
          return t('todo_read_message', { stakeholder })
        return t('done_read_message', { stakeholder, date })
      case ActivityObjectType.Form:
        if (activity.status === ActivityStatus.Active)
          return t('todo_submit_form', { stakeholder })
        return t('done_submit_form', { stakeholder, date })
      case ActivityObjectType.Checklist:
        if (activity.status === ActivityStatus.Active)
          return t('todo_complete_checklist', { stakeholder })
        return t('done_complete_checklist', { stakeholder, date })
      default:
        if (activity.status === ActivityStatus.Active)
          return t('todo_complete_activity', { stakeholder })
        return t('done_complete_activity', { stakeholder })
    }
  }

  const createLabelColor = (): LozengeColors => {
    const { status, object, resolution } = activity
    switch (status) {
      case ActivityStatus.Active:
        return object.type === ActivityObjectType.PluginAction ||
          object.type === ActivityObjectType.ApiCall ||
          object.type === ActivityObjectType.EmrReport
          ? LozengeColors.BLUE
          : LozengeColors.RED
      case ActivityStatus.Canceled:
      case ActivityStatus.Failed:
        return LozengeColors.GREY
      case ActivityStatus.Done:
        return resolution === ActivityResolution.Failure
          ? LozengeColors.RED
          : LozengeColors.GREEN
      case ActivityStatus.Expired:
        return LozengeColors.RED
      default:
        return LozengeColors.BLUE
    }
  }

  const createActionIcon = (): JSX.Element | null => {
    const { object, indirect_object, icon_url } = activity
    switch (object.type) {
      case ActivityObjectType.Message:
        return <ActionIcon actionType={ActionType.Message} size='lg' />
      case ActivityObjectType.Form:
        return <ActionIcon actionType={ActionType.Form} size='lg' />
      case ActivityObjectType.Checklist:
        return <ActionIcon actionType={ActionType.Checklist} size='lg' />
      case ActivityObjectType.Calculation:
        return <ActionIcon actionType={ActionType.Calculation} size='lg' />
      case ActivityObjectType.ClinicalNote:
        return <ActionIcon actionType={ActionType.ClinicalNote} size='lg' />
      case ActivityObjectType.PluginAction:
        if (indirect_object?.name?.toLocaleLowerCase() === 'sendgrid') {
          return <Sendgrid />
        }
        return (
          <ActionIcon
            actionType={ActionType.Plugin}
            icon_url={icon_url ?? undefined}
            size='lg'
          />
        )
      // Fallthrough behaviour since Emr icon is used for both API Calls and EMR Reports
      case ActivityObjectType.EmrReport:
      case ActivityObjectType.ApiCall:
        return <ActionIcon actionType={ActionType.PushToEmr} size='lg' />
      default:
        return <ActionIcon size='lg' />
    }
  }

  const createPendingActivityButtonText = (
    activity_object_type: ActivityObjectType,
  ): string => {
    switch (activity_object_type) {
      case ActivityObjectType.Form:
        return t('activityfeed:fill_form_button')
      case ActivityObjectType.Message:
        return t('activityfeed:read_message_button')
      case ActivityObjectType.Checklist:
        return t('activityfeed:complete_checklist_button')
      // Fallthrough behaviour for these object types since they share the same button text
      case ActivityObjectType.EmrReport:
      case ActivityObjectType.ApiCall:
      case ActivityObjectType.ClinicalNote:
      case ActivityObjectType.PluginAction:
        return t('activityfeed:view_details')
      default:
        return t('activityfeed:default_pending_button')
    }
  }

  const createCompletedActivityButtonText = (
    activity_object_type: ActivityObjectType,
  ): string => {
    if (activity_object_type === ActivityObjectType.ClinicalNote) {
      return t('activityfeed:read_note_button')
    }
    if (activity_object_type === ActivityObjectType.PluginAction) {
      return t('activityfeed:view_details')
    }
    return t('activityfeed:view_activity_button_label', {
      activity: t(
        `activityfeed:${activity_object_type
          .split('_')
          .map(capitalize)
          .join(' ')}`,
      ),
    })
  }

  const getRemindersCountTranslation = (subActivity: SubActivity): string => {
    const count = activity.sub_activities.filter(
      ({ action, date }) =>
        date <= subActivity.date && action === ActivityAction.Remind,
    ).length
    return t(`count_${count}`)
  }

  const createMessageSubActivitySummaryText = (
    subActivity: SubActivity,
  ): string => {
    const { object, indirect_object } = activity

    switch (subActivity.action) {
      case ActivityAction.Activate:
        return t('activityfeed:message_active', {
          indirect_object,
          object,
        })
      case ActivityAction.Deliver:
        return t('activityfeed:message_delivered', {
          object,
          indirect_object,
        })
      case ActivityAction.Read:
        return t('activityfeed:message_read', subActivity)
      case ActivityAction.Send:
        return t('activityfeed:message_sent', {
          object,
          indirect_object,
        })
      case ActivityAction.Expired:
        return t('activityfeed:message_expired', { object })
      case ActivityAction.Generated:
        return t('activityfeed:clinical_note_generated')
      case ActivityAction.Remind: {
        const email =
          isNil(indirect_object) || isNil(indirect_object?.email)
            ? ''
            : `(${indirect_object?.email})`
        return t('activityfeed:reminder_event', {
          firstName: indirect_object?.name,
          email,
          reminderCount: getRemindersCountTranslation(subActivity),
        })
      }
      default:
        return ''
    }
  }
  const createDecisionSubActivitySummaryText = (
    subActivity: SubActivity,
  ): string => {
    return subActivity.text?.en ?? ''
  }

  const createClinicalNoteSubActivitySummaryText = (
    subActivity: SubActivity,
  ): string => {
    switch (subActivity.action) {
      case ActivityAction.Generated:
        return t('activityfeed:clinical_note_generated')
      default:
        return ''
    }
  }

  const createExtensionSubActivitySummaryText = (
    subActivity: SubActivity,
  ): string => {
    const { text, error, error_category } = subActivity
    switch (subActivity.action) {
      case ActivityAction.Delegated:
        return t('activityfeed:extension_action_delegated', {
          object: subActivity.object,
          subject: subActivity.subject,
        })
      case ActivityAction.Skipped:
        return t('activityfeed:extension_action_skipped', {
          subject: subActivity.subject,
        })
      case ActivityAction.Complete:
        return t('activityfeed:extension_action_completed', {
          subject: subActivity.subject,
        })
      case ActivityAction.Reported: {
        const errorMessage = text?.en ?? ''
        const errorCategory = error_category ?? ''
        const errorDetails = error ?? ''
        const message = [errorMessage, errorCategory, errorDetails].join('\n\n')
        return t('activityfeed:extension_action_reported', {
          subject: subActivity.subject,
          message,
        })
      }
      case ActivityAction.Failed:
        return t('activityfeed:extension_action_failed', {
          subject: subActivity.subject,
        })
      default:
        return ''
    }
  }

  const createFormSubActivitySummaryText = (
    subActivity: SubActivity,
  ): string => {
    const { object, indirect_object } = activity
    const { subject } = subActivity

    switch (subActivity.action) {
      case ActivityAction.Activate:
        return t('activityfeed:form_submit_active', {
          indirect_object,
          object,
        })
      case ActivityAction.Submitted:
        return t('activityfeed:form_submitted', { subject, object })
      case ActivityAction.Remind:
        return t('activityfeed:reminder_event', {
          firstName: indirect_object?.name,
          email: indirect_object?.email ?? '',
          reminderCount: getRemindersCountTranslation(subActivity),
        })
      case ActivityAction.Skipped:
      case ActivityAction.Expired:
        return t('activityfeed:form_expired', { object })
      default:
        return ''
    }
  }

  const createChecklistSubActivitySummaryText = (
    subActivity: SubActivity,
  ): string => {
    const { object, indirect_object } = activity
    const { subject } = subActivity

    switch (subActivity.action) {
      case ActivityAction.Activate:
        return t('activityfeed:checklist_submit_active', {
          indirect_object,
          object,
        })
      case ActivityAction.Submitted:
        return t('activityfeed:checklist_submitted', {
          subject,
          object,
        })
      case ActivityAction.Remind:
        return t('activityfeed:reminder_event', {
          firstName: indirect_object?.name,
          email: indirect_object?.email ?? '',
          reminderCount: getRemindersCountTranslation(subActivity),
        })
      default:
        return ''
    }
  }

  const createCalculationSubActivitySummaryText = (
    subActivity: SubActivity,
  ): string => {
    const { object } = activity

    switch (subActivity.action) {
      case ActivityAction.Computed:
        return t('activityfeed:calculation_computed', {
          object,
        })
      case ActivityAction.Failed:
        return t('activityfeed:calculation_failed', { object })
      default:
        return ''
    }
  }

  const createEmrReportSubActivitySummaryText = (
    subActivity: SubActivity,
  ): string | JSX.Element => {
    const emrPreview = t('activityfeed:emr_processed_preview', {
      returnObjects: true,
    })
    const emrSentTranslation = t('activityfeed:emr_sent', {
      returnObjects: true,
    })
    const emrSendFailureTranslation = t('activityfeed:emr_send_failure', {
      returnObjects: true,
    })
    const emrSuccessTranslation = t('activityfeed:emr_processed_success', {
      returnObjects: true,
    })
    const emrFailureTranslation = t('activityfeed:emr_processed_failure', {
      returnObjects: true,
    })
    // As stream_id is used only in orchestration, we can tell if we are in
    // Preview mode in Studio or not by its presence in the activity object
    // no stream_id means we are running in preview
    if (isNil(activity.stream_id)) {
      switch (subActivity.action) {
        case ActivityAction.Send:
          return <span>{emrSentTranslation}</span>
        case ActivityAction.FailedToSend:
          return <span>{emrSendFailureTranslation}</span>
        case ActivityAction.Processed:
          return (
            <span>
              {emrPreview[0]}
              <Tooltip
                arrow
                highlighted
                info={t('activityfeed:emr_processed_preview_tooltip')}
              >
                <span>{emrPreview[1]}</span>
              </Tooltip>
              {emrPreview[2]}
            </span>
          )
        default:
          return ''
      }
    }

    const request_id = subActivity.object?.id
    const requestIdBtn = !isNil(request_id) ? (
      <Tooltip
        arrow
        highlighted
        info={`Copy request id(${request_id}) to clipboard`}
      >
        <IconButton
          variant='floating'
          onClick={() => {
            void navigator.clipboard.writeText(request_id)
          }}
          style={{ padding: 0 }}
          iconSize='xs'
        >
          <Duplicate />
        </IconButton>
      </Tooltip>
    ) : (
      <></>
    )
    switch (subActivity.action) {
      case ActivityAction.Send:
        return (
          <span>
            {emrSentTranslation[0]}
            {requestIdBtn}
            {emrSentTranslation[1]}
          </span>
        )
      case ActivityAction.FailedToSend:
        return (
          <span>
            {emrSendFailureTranslation[0]}
            {requestIdBtn}
            {emrSendFailureTranslation[1]}
          </span>
        )
      case ActivityAction.Processed:
        return (
          <span>
            {emrSuccessTranslation[0]}
            {requestIdBtn}
            {emrSuccessTranslation[1]}
          </span>
        )
      case ActivityAction.Failed:
        return (
          <span>
            {emrFailureTranslation[0]}
            {requestIdBtn}
            {emrFailureTranslation[1]}
          </span>
        )
      default:
        return ''
    }
  }

  const createApiCallSubActivitySummaryText = (
    subActivity: SubActivity,
  ): string => {
    switch (subActivity.action) {
      case ActivityAction.Complete:
        return t('activityfeed:api_call_completed')
      case ActivityAction.Send:
        return t('activityfeed:api_call_sent')
      case ActivityAction.Failed:
        return t('activityfeed:api_call_failed')
      default:
        return ''
    }
  }

  const createSubActivitySummaryText = (
    subActivity: SubActivity,
  ): string | JSX.Element => {
    const { object } = activity
    switch (object.type) {
      case ActivityObjectType.ClinicalNote:
        return createClinicalNoteSubActivitySummaryText(subActivity)
      case ActivityObjectType.Message:
        return createMessageSubActivitySummaryText(subActivity)
      case ActivityObjectType.Form:
        return createFormSubActivitySummaryText(subActivity)
      case ActivityObjectType.Checklist:
        return createChecklistSubActivitySummaryText(subActivity)
      case ActivityObjectType.Calculation:
        return createCalculationSubActivitySummaryText(subActivity)
      case ActivityObjectType.EmrReport:
        return createEmrReportSubActivitySummaryText(subActivity)
      case ActivityObjectType.ApiCall:
        return createApiCallSubActivitySummaryText(subActivity)
      case ActivityObjectType.PluginAction:
        return createExtensionSubActivitySummaryText(subActivity)
      case ActivityObjectType.Decision:
        return createDecisionSubActivitySummaryText(subActivity)
      default:
        return ''
    }
  }

  const createSubActivityAvatar = (
    _activity: Activity,
    subActivity: SubActivity,
  ): string => {
    const { object } = _activity
    const { subject, action, error } = subActivity
    if (object.type === ActivityObjectType.EmrReport) {
      return action === ActivityAction.Failed ? 'emr_failure' : 'emr_success'
    }
    if (object.type === ActivityObjectType.PluginAction) {
      // TODO: Should we use Ava or EMR success/failure icons for Plugin Action activity delegation?
      if (action === ActivityAction.Failed) {
        return 'emr_failure'
      }
      if (action === ActivityAction.Delegated) {
        return 'ava'
      }
      return isNil(error) ? 'emr_success' : 'emr_failure'
    }

    switch (subject.type) {
      case ActivitySubjectType.Awell:
        return 'ava'
      case ActivitySubjectType.Stakeholder:
      default:
        return subActivity.subject.name
    }
  }

  const getLastUpdatedTimestamp = (): string | undefined => {
    const { sub_activities } = activity
    if (isNil(sub_activities) || sub_activities.length === 0) {
      return undefined
    }
    const lastSubActivity = sub_activities[sub_activities.length - 1]
    return lastSubActivity.date
  }

  const createActivityContent = (): ActivityContent => {
    return {
      summaryText: createActivitySummary(),
      stepName: activity.container_name ?? '',
      stepLabelColor:
        Colors[
        (activity.label?.color as keyof typeof Colors) ?? Colors.stepDefault
        ],
      stepLabelName: activity.label?.text ?? '',
      activityStatus: createStatus(),
      activityStatusSummary: createActivityStatusSummary(),
      activityStatusColor: createLabelColor(),
      timestampLabel: t('activityfeed:activated_at'),
      timestamp: activity.date,
      stakeholder: activity.indirect_object?.name,
      ActivityIcon: createActionIcon(),
      buttonTextPending: createPendingActivityButtonText(activity.object.type),
      buttonTextComplete: createCompletedActivityButtonText(
        activity.object.type,
      ),
      trackName: activity.track?.title ?? '',
      id: activity.id,
      lastUpdatedTimestamp: getLastUpdatedTimestamp(),
    }
  }

  const createEventsContent = (): Array<ActivityEventType> => {
    const subActivityContent: Array<ActivityEventType> =
      activity.sub_activities.map((subActivity: SubActivity) => {
        return {
          id: subActivity.id,
          content: {
            summaryText: createSubActivitySummaryText(subActivity),
            timestamp: subActivity.date,
            avatar: createSubActivityAvatar(activity, subActivity),
          },
        }
      })
    return subActivityContent
  }

  const content = createActivityContent()
  const events = createEventsContent()

  return {
    content,
    events,
  }
}
